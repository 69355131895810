// Source: https://haseebmajid.dev/blog/toc-in-gatsby

import * as React from "react"
import { Heading, TableOfContentsProps } from "../types/table-of-contents-props"

const TableOfContents = (props: TableOfContentsProps) => {
  const { headings, options } = props

  if (!headings || headings.length <= 0) return <></>
  const filteredHeadings: Heading[] = []
  headings.forEach(heading => {
    if (heading.depth >= options.minHeading && heading.depth <= options.maxHeading) {
      filteredHeadings.push(heading)
    }
  })

  return (
    <div className="table-of-contents" style={{ padding: "10px 20px" }}>
      <p style={{ marginBottom: "10px" }}>
        <strong>ON THIS PAGE</strong>
      </p>
      <div>
        <ul>
          {filteredHeadings.map(heading => (
            <li key={heading.id}>
              <a key={heading.id} href={`#${heading.id}`} className={`toc-heading-h${heading.depth}`}>{heading.value}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default TableOfContents
