import React from "react"
import { graphql } from "gatsby"
import Footer from "../components/footer"
import TableOfContents from "../components/tableOfContents"
import { MarkdownRemark } from "../types/markdown-page-props"
import { SiteMetadata } from "../types/site-metadata"

interface MarkdownTemplateProps {
  data: {
    markdownRemark: MarkdownRemark
    site: {
      siteMetadata: SiteMetadata
    }
  }
  pageContext: {
    supportLink: string
    privacyPolicyLink: string
    apiDocumentationLink: string
  }
}

export default function Template(props: MarkdownTemplateProps) {
  const { data } = props
  const { markdownRemark, site } = data
  const { frontmatter, html, headings } = markdownRemark
  const { siteMetadata } = site
  const tableOfContentsConfig = siteMetadata?.tableOfContents || {}
  const { supportLink, privacyPolicyLink } = props.pageContext

  return (
    <>
      {/* col-1 */}
      <div className="column is-8-fullhd is-8-desktop is-8-tablet is-12-mobile">
        <div className="content">
          <main>
            <section>
              <h1>{frontmatter.title}</h1>
              <hr></hr>
              <div className="container" dangerouslySetInnerHTML={{ __html: html }} />
            </section>
          </main>
          <Footer supportLink={supportLink} privacyPolicyLink={privacyPolicyLink} />
        </div>
      </div>
      {/* col-1 - end */}
      {/* ------------ */}
      {/* col-2 - start */}
      <div className="column is-3-fullhd is-3-desktop is-4-tablet is-hidden-mobile">
        <div className="right-sidebar">
          <TableOfContents headings={headings} options={tableOfContentsConfig} />
        </div>
      </div>
      {/* col-2 - end */}
    </>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
      headings {
        id
        depth
        value
      }
    }
    site {
      siteMetadata {
        title
        tableOfContents {
          minHeading
          maxHeading
        }
      }
    }
  }
`
